import useSecureAPI from "../hooks/usesecureapi";




const useEventService = () => {
    const secureAPI = useSecureAPI();

    const getEvents = async (page: any, pageSize: any) => {
        // let pageno = page == 0 ? 1 : page+1;
        const response = await secureAPI.get(`event/allevent?page=${page + 1}&limit=${pageSize}`);
        return response.data;
    };

    const createEvent = async (data: any) => {
        const response = await secureAPI.post(`event/addevent`, data);
        return response.data;
    };

    const getEventById = async (id: any) => {
        const response = await secureAPI.get(`event/eventbyid/${id}`);
        return response.data;
    }

    const deleteEvent = async (id: any) => {
        const response = await secureAPI.delete(`event/deleteevent/${id}`);
        return response.data;
    };

    const updateEvent = async (id: any, data: any) => {
        const response = await secureAPI.put(`event/updateevent/${id}`, data);
        return response.data;

    }





    return {
        getEvents,
        createEvent,
        deleteEvent,
        getEventById,
        updateEvent
    };
};

export default useEventService;