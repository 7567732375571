import GridDX from "../CustomGrid";
import PageTitle from "../../PageTitle";
import { Outlet } from "react-router-dom";



function ListPageLayout() {
    return (
        <GridDX container sx={{ width: "100%" }}>
            <GridDX item xs={12}>
                <PageTitle />
            </GridDX>
            <GridDX item xs={12}>
                <Outlet />
            </GridDX>
        </GridDX>
    );
};

export default ListPageLayout;


