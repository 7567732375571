import { HashRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/login";
import Events from "./pages/events";
import DashboardLayout from "./components/Layout";
import ListPageLayout from "./components/Layout/ListpageLayout";
import { useEffect, useState } from "react";
import { useAuthContext } from "./context/authcontext";
import LoadingOverlay from "./components/loadingoverlay/LoadingOverlay";
import ProtectedRoute from "./components/route/ProtectedRoute";
import NotificationBarDX from "./components/NotificationBar";
import Sponsorships from "./pages/sponsorships";

function App() {
  return (
    <div className="min-h-screen font-roboto">
      <NotificationBarDX />
      <ApplicationRoutes />
    </div>
  );
}

const ApplicationRoutes = () => {
  const { authInitialized, isLoggedIn } = useAuthContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authInitialized) setLoading(false);
  }, [authInitialized]);

  if (loading) return <LoadingOverlay />;

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Login />} />

        <Route
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <DashboardLayout />
            </ProtectedRoute>
          }
        >
          <Route element={<ListPageLayout />}>
            <Route path="/events" element={<Events />} />
            <Route path="/sponsorships" element={<Sponsorships />} />
          </Route>
        </Route>
      </Routes>
    </HashRouter>
  );
};

export default App;
