import { useEffect, useState } from "react";
import AddEditModalDX from "../business/addeditmodaldx";
// import CheckBoxDX from "../controls/checkboxdx";
import { useNotificationContext } from "../../context/notificationcontext";

import GridDX from "../Layout/CustomGrid";
import TextFieldDX from "../CustomTextfield";
import LoadingOverlay from "../loadingoverlay/LoadingOverlay";
import SelectListDX from "../controls/selectlistdx";
import DatePickerDX from "../controls/datepickerdx";
import { Typography } from "@mui/material";
import ButtonDX from "../Button";
import { AddCircleOutlined, RemoveCircleOutline } from "@mui/icons-material";
import useSponsorshipService from "../../shared/services/sponsorshipservice";

const SponsorshipModal = (props: any) => {
  const { open, handleClose, sponsorshipId, actionType } = props;
  const { setError, setInfo } = useNotificationContext();

  const { getSponsorshipById, updatePaymentStatus, updateSponsorship } =
    useSponsorshipService();
  const defaultValues = {
    comment: "",
    email: "",
    name: "",
    phoneNumber: "",
    sponsorDate: "",
    sponsorMessage: "",
    sponsorName: "",
    paymentStatus: null,
  };

  const [sponsorshipData, setSponsorshipData] = useState(defaultValues);

  const [isLoading, setIsLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (sponsorshipId) {
      setIsLoading(true);
      getSponsorshipById(sponsorshipId)
        .then((res) => {
          setSponsorshipData(res.response[0]);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  }, []);

  const validateForm = () => {
    const newErrors: any = {};

    if (!sponsorshipData.name) newErrors["name"] = "Name is required";
    if (!sponsorshipData.email) newErrors["email"] = "Email is required";
    if (!sponsorshipData.phoneNumber)
      newErrors["phoneNumber"] = "Phone Number is required";
    if (!sponsorshipData.sponsorMessage)
      newErrors["sponsorMessage"] = "Sponsor Message is required";
    if (!sponsorshipData.sponsorName)
      newErrors["sponsorName"] = "Sponsor Name is required";
    if (!sponsorshipData.comment) newErrors["comment"] = "Comment is required";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onSave = () => {
    if (validateForm()) {
      setIsLoading(true);
      // const operation = sponsorshipId && actionType == "edit" ? updateSponsorship(sponsorshipId, { }) : createEvent({ ...sponsorshipData, slots: slots })
      updateSponsorship(sponsorshipId, {
        name: sponsorshipData.name,
        email: sponsorshipData.email,
        phoneNumber: sponsorshipData.phoneNumber,
        sponsorMessage: sponsorshipData.sponsorMessage,
        sponsorName: sponsorshipData.sponsorName,
        sponsorDate: sponsorshipData.sponsorDate,
        comment: sponsorshipData.comment,
      })
        .then(() => {
          setInfo("Sponsorship updated successfully");
          handleClose(true);
        })
        .catch((err) => setError(err))
        .finally(() => setIsLoading(false));
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e.target;
    setIsChanged(true);
    setSponsorshipData({
      ...sponsorshipData,
      [name]: type == "checkbox" ? checked : value,
    });
  };

  const handleUpdatePaymentStatus = (e: any) => {
    const { name, value } = e.target;
    setIsLoading(true);
    updatePaymentStatus(sponsorshipId, { paymentStatus: value })
      .then(() => {
        setInfo("Payment status updated successfully");
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
  };
  return (
    <AddEditModalDX
      open={open}
      handleClose={handleClose}
      isSaving={isLoading}
      isChanged={isChanged}
      title={
        sponsorshipId && actionType == "edit"
          ? "Edit Sponsorship"
          : "Add Sponsorship"
      }
      maxWidth="sm"
      onSaveClick={onSave}
      onYesClick={handleClose}
    >
      {isLoading && (
        <LoadingOverlay styles={{ height: "100%", width: "100%", left: 0 }} />
      )}
      <GridDX container columnSpacing={1} rowSpacing={2} sx={{ pt: 1 }}>
        <GridDX item xs={12}>
          <TextFieldDX
            label={"Name"}
            name="name"
            value={sponsorshipData.name}
            onChange={handleInputChange}
            error={errors["name"]}
          />
        </GridDX>

        <GridDX item xs={6}>
          <TextFieldDX
            label={"Email"}
            name="email"
            value={sponsorshipData.email}
            onChange={handleInputChange}
            error={errors["email"]}
          />
        </GridDX>

        <GridDX item xs={6}>
          <TextFieldDX
            label={"Phone Number"}
            name="phoneNumber"
            value={sponsorshipData.phoneNumber}
            onChange={handleInputChange}
            error={errors["phoneNumber"]}
          />
        </GridDX>
        {/* sponsor message */}
        <GridDX item xs={6}>
          <TextFieldDX
            label={"Sponsor Message"}
            name="sponsorMessage"
            value={sponsorshipData.sponsorMessage}
            onChange={handleInputChange}
            error={errors["sponsorMessage"]}
          />
        </GridDX>
        {/* sponsor name */}
        <GridDX item xs={6}>
          <TextFieldDX
            label={"Sponsor Name"}
            name="sponsorName"
            value={sponsorshipData.sponsorName}
            onChange={handleInputChange}
            error={errors["sponsorName"]}
          />
        </GridDX>
        {/* comments */}
        <GridDX item xs={12}>
          <TextFieldDX
            label={"Comments"}
            name="comment"
            value={sponsorshipData.comment}
            onChange={handleInputChange}
            error={errors["comment"]}
          />
        </GridDX>

        <GridDX item xs={12}>
          <SelectListDX
            label={"Payment Status"}
            name="paymentStatus"
            onChange={handleUpdatePaymentStatus}
            value={sponsorshipData.paymentStatus}
            items={[
              { text: "Paid", value: "PAID" },
              { text: "Unpaid", value: "UNPAID" },
            ]}
            InputLabelProps={{ shrink: !!sponsorshipData.paymentStatus }}
          />
        </GridDX>
      </GridDX>
    </AddEditModalDX>
  );
};

export default SponsorshipModal;
