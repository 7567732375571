import useSecureAPI from "../hooks/usesecureapi";




const useSponsorshipService = () => {
    const secureAPI = useSecureAPI();

    const getAllSponsorships = async (page: any, pageSize: any) => {
        // let pageno = page == 0 ? 1 : page+1;
        const response = await secureAPI.get(`sponsor/user/allsponsorusers?page=${page + 1}&limit=${pageSize}`);
        return response.data;
    };


    const getSponsorshipById = async (id: any) => {
        const response = await secureAPI.get(`sponsor/user/sponsoruser/${id}`);
        return response.data;
    };

    // update sponsor user
    //sponsor/user/updatesponsoruser
    const updateSponsorship = async (id: any, data: any) => {
        const response = await secureAPI.put(`sponsor/user/updatesponsoruser/${id}`, data);
        return response.data;
    };

    // update payment status
    //sponsor/user/paymentstatus
    const updatePaymentStatus = async (id: any, data: any) => {
        const response = await secureAPI.put(`sponsor/user/paymentstatus/${id}`, data);
        return response.data;
    };

    const deleteSponsorship = async (id: any) => {
        const response = await secureAPI.delete(`sponsor/user/delete/${id}`);
        return response.data;
    }



    return {
        getAllSponsorships,
        getSponsorshipById,
        updateSponsorship,
        updatePaymentStatus,
        deleteSponsorship
    };
};

export default useSponsorshipService;