import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";

const PageTitle = () => {
    const location = useLocation();

    const routeMap = new Map<string, string>([
        ["events", "Events"],
        ["sponsorships", "Sponsorships"],

    ]);

    const [pageHeading, setPageHeading] = useState("Dashboard");

    useEffect(() => {
        let pathName = location.pathname.substring(1);
        const idPath = pathName.indexOf("/");
        if (idPath != -1) {
            pathName = pathName.substring(0, idPath);
        }
        updatePageHeading(routeMap.get(pathName));
    }, [location.pathname]);

    const updatePageHeading = async (newPageHeading: string | undefined) => {
        setPageHeading(newPageHeading ?? "Page Title");
    };

    return (
        <Typography
            variant="h4"
            component="div"
            sx={{ flexGrow: 1, fontWeight: "bold", py: 2 }}
        >
            {pageHeading}
        </Typography>
    );
};

export default PageTitle;
