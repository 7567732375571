import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import ListPageDX from "../components/Listpage";
import useEventService from "../shared/services/eventservices";
import EventModal from "../components/editmodals/eventmodal";
import moment from "moment";

const Events = () => {
  const { getEvents, deleteEvent } = useEventService();
  const [eventId, setEventId] = useState<any>(null)
  const [actionType, setActionType] = useState<any>(null)
  const [open, setOpen] = useState(false);
  const [refetch , setRefetch] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "eventName",
      headerName: "Event Name",
      flex: 2,
    },
    {
      field: "eventType",
      headerName: "Event Type",
      flex: 1,
    },
    {
      field: "eventDate",
      headerName: "Event Date",
      flex: 1,
      valueFormatter: (params: any) => {
        return moment(params.value).format("ddd, MMM D, YYYY")
      }
    },
    {
      field: "availableSlots",
      headerName: "Available Slots",
      flex: 1,
    },

  ];

  const onEdit = (event: any) => {
    setEventId(event._id);
    setOpen(!open);
    setActionType("edit");
  };

  const onCopy = (event: any) => {
    setEventId(event._id);
    setOpen(!open);
    setActionType("copy");
    
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setEventId(null);
    setOpen(!open);
    setActionType(null);

    if (refreshPage) setRefetch(!refetch);
  }


  return (
    <>
      {open && (
        <EventModal
          open={open}
          handleClose={onClose}
          eventId={eventId}
          actionType={actionType}

        />
      )}
      <ListPageDX
        listTitle="Events"
        columns={columns}
        getRowId={(row: any) => row._id}
        toCreate={toCreate}
        onEdit={onEdit}
        onCopy={onCopy}
        searchAndFilter={true} 
        refetch={refetch}
        onDelete={deleteEvent}
        deleteMessage="Event deleted successfully"
        fetchData={getEvents}
      />
    </>
  );
};

export default Events;
