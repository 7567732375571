



import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Tooltip,
  ListItemIcon,
  Avatar,
} from "@mui/material";
import {
  AccountCircle,
  NotificationsNone as NotificationIcon,
  MoreVert as MoreIcon,
  Menu as MenuIcon,
  Logout,
} from "@mui/icons-material";



import MobileMenu from "./mobilemenu";
// import ProfileModal from "../../editmodals/profilemodal";
import { useNavigate } from "react-router-dom";
import BoxDX from "../CustomBox";

import { useAuthContext } from "../../context/authcontext";









const AccountMenu: React.FC = (props: any) => {
  const navigate = useNavigate();
  const { signOut } = useAuthContext();

  const [openProfile, setOpenProfile] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogoutClick = () => {
    handleClose();
    signOut();
    navigate("/");
  };

  const onProfileClick = () => {
    setOpenProfile(!openProfile);
    handleClose();
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={"Account settings"}>
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={props.open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={props.open ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}></Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem onClick={onProfileClick}>
          <ListItemIcon>
            <Avatar sx={{ width: 28, height: 28 }} />
          </ListItemIcon>
          Profile
        </MenuItem> */}
        <MenuItem onClick={onLogoutClick}>
          <ListItemIcon sx={{ paddingLeft: 0.5 }}>
            <Logout fontSize="small" sx={{ width: 27, height: 25 }} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      {/* {openProfile && (
          <ProfileModal
            open={openProfile}
            onClose={() => setOpenProfile(false)}
          />
        )} */}
    </React.Fragment>
  );
};


export { AccountMenu }