import { TextField } from "@mui/material/";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

const TextFieldDX = (props: any) => {
  const helperText = props.helperText;
  const controlType = props.type ?? "text";
  const allowCopyPaste = props.allowCopyPaste ?? "true";
  const isError = props.error ? true : false;
  const loading = props.loading ?? false;



  const handleCopyPaste = (e: any) => {
    e.preventDefault();
  };

  if (loading)
    return (
      <Skeleton
        containerClassName="skeleton-container"
        style={{ height: 56 }}
      />
    );
  else if (props.readOnly)
    return (
      <TextField
        {...props}
        variant="standard"
        fullWidth
        helperText={helperText}
        InputProps={{
          readOnly: true
        }}
        InputLabelProps={{ shrink: true }}
        sx={  props.sx   }
        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-primary-light"
    
      />
    );
  else
    return (
      <TextField
        fullWidth
        variant="outlined"
        error={isError}
        helperText={props.error ?? helperText}
        {...props}
        value={props.value ?? ""}
        onCut={allowCopyPaste ? null : handleCopyPaste}
        onCopy={allowCopyPaste ? null : handleCopyPaste}
        onPaste={allowCopyPaste ? null : handleCopyPaste}
        onWheel={(e: any) => e.target?.blur()}
        onKeyDown={(e:any) => {
          if (props?.type === "number" && (e.which === 38 || e.which === 40)) {
            e.preventDefault();
          }
        }}
        sx={props.sx }
        className={`w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-primary-light ${
          isError ? 'border-red-500' : ''
        }`}
      />
    );
};

export default TextFieldDX;
