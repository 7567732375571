import Sidebar from './Sidebar';
import AppBarDX from '../AppBar';
import { Outlet } from 'react-router-dom';


const DashboardLayout = () => {
  return (
    <div className="flex h-screen">
      <Sidebar />

      {/* Main content */}
      <main className="p-4 max-w-fit" style={{ flex: 1, minWidth: 0 }}>

        <AppBarDX />
        <>
          <Outlet />
        </>
      </main>
    </div>
  );
};


export default DashboardLayout;

