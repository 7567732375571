const primary = {
    light: '#f4dea4',
    DEFAULT: '#e1a400',
    dark: '#c18600', // A slightly darker shade than DEFAULT
}

const secondary = {
    light: '#93C5FD',
    DEFAULT: '#3B82F6',
    dark: '#1E3A8A',
}


export { primary, secondary } 