import { Dialog, DialogTitle, DialogContent, Typography, DialogActions } from "@mui/material";
import GridDX from "../Layout/CustomGrid";
import ButtonDX from "../Button";


const DeleteModal = (props: any) => {

  const { open, onCancelClick, onDeleteClick } = props

  return (
    <Dialog
      open={open}
      onClose={onCancelClick}
      fullWidth
      maxWidth={"xs"}
      disableEscapeKeyDown
      sx={{  }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>Are You Sure?</DialogTitle>
      <DialogContent>
        <GridDX justifyContent="center" direction="column">
          <Typography sx={{ textAlign: "center" }}  >
            You want to delete this entry
          </Typography>
        </GridDX>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-around" }}>
        <ButtonDX
          variant="outlined"
          onClick={onCancelClick}
        >
          Cancel
        </ButtonDX>
        <ButtonDX
          onClick={onDeleteClick}
        >
          Delete
        </ButtonDX>
      </DialogActions>
    </Dialog >
  );
}

export default DeleteModal;