import axios from "axios";

export const apiCall = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  timeout: 10000, // 10 seconds
});

export const secureAPI = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
});

// Add a response interceptor
apiCall.interceptors.response.use(
  // Any status code that lie within the range of 2xx cause this function to trigger
  (response) => response,
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  async (error) => {
    console.log("api response error:", { error });

    if (error.response?.data instanceof Blob) {
      const message = await error.response?.data.text();
      console.log({ message });
      const messageObj = JSON.parse(message);
      console.log({ messageObj });
      if (messageObj) return Promise.reject(messageObj);
      else return Promise.reject(message);
    } else {
      const errorMessage =
        error?.response?.data?.message ?? error.message ?? error;

      console.log({ errorMessage });
      return Promise.reject(errorMessage);
    }
  }
);

