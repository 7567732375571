import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { useState } from "react";
import ConfirmModal from "../controls/confirmmodal";
import ButtonDX from "../Button";

/**
 * JSX Component for Adding and Editing Data inside Dialog
 * @param {boolean} open boolean to open dialog
 * @param {Function} handleClose function to handle dialog close
 * @param { false | Breakpoint | undefined } maxWidth determine the max-width of the dialog
 * @param {string} title string title for dialog
 * @param {boolean} isChanged boolean to track change in data
 * @param {boolean} isSaving boolean to track data saving
 * @param {JSX.Element} children JSX Elements for dialog content
 * @param {Function} onSaveClick function to handle dialog close
 * @param {string} saveLabel string label for save button
 * @param {string} cancelLabel string label for cancel button
 */
const AddEditModalDX = (props: any) => {
    const [open, setOpen] = useState(false);

    const onClose = () => {
        if (props.isChanged) setOpen(true);
        else props.handleClose();
    };

    return (
        <Dialog
            open={props.open}
            onClose={onClose}
            fullWidth
            maxWidth={props.maxWidth ?? "xl"}
            disableEscapeKeyDown
            {...props}
            sx={{ ...props.sx, p: { xs: 0, sm: 2 } }}
        >
            <DialogTitle sx={{ textAlign: "center" }}>{props.title}</DialogTitle>
            <DialogContent>
                {open && (
                    <ConfirmModal
                        open={open}
                        onYesClick={props.handleClose}
                        onNoClick={() => setOpen(false)}
                    />
                )}
                {props.children}
            </DialogContent>
            <DialogActions sx={{ flexWrap: "wrap", justifyContent: "space-around" }}>
                {/* <ButtonDX
                    variant="outlined"
                    onClick={onClose}
                    sx={{ mb: { xs: 2, sm: "auto" } }}
                >
                    {props.cancelLabel ?? "Cancel"}
                </ButtonDX> */}
                <ButtonDX
                    onClick={onClose}
                >
                    {props.cancelLabel ?? "Cancel"}
                </ButtonDX>
                <ButtonDX
                    // disabled={!props.isChanged}
                    onClick={props.onSaveClick}
                >
                    {props.saveLabel ?? "Save"}
                </ButtonDX>
            </DialogActions>
        </Dialog>
    );
};

export default AddEditModalDX;
