import {
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import Skeleton from "react-loading-skeleton";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useAuthContext } from "../../context/authcontext";

const DatePickerDX = (props: any) => {

  const { userData } = useAuthContext();
  const theme = useTheme();
  const isError = props.error ? true : false;
  const errorText = props.error ?? "";
  const helperText = props.helperText;
  const dateFormat = props.format ?? "DD-MM-YYYY"; //DATE_FORMAT;
  const platform = navigator?.userAgent ?? "";
  const loading = props.loading ?? false;

 
  if (loading)
    return (
      <Skeleton
        containerClassName="skeleton-container"
        style={{ height: 56 }}
      />
    );
  else if (props.readOnly)
    return (
      <TextField
        {...props}
        value={props.value ? moment(props.value).format(dateFormat) : ""}
        variant="outlined"
        fullWidth
        disabled
        placeholder={helperText}
        helperText=""
        InputLabelProps={{ shrink: true }}
      />
    );

  else {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          {...props}
          variant="outlined"
          inputVariant="outlined"
          slotProps={{
            textField: {
              error: isError,
              helperText: errorText ?? helperText,
            },
          }}
          
          value={props.value && moment(props.value)}
          format={dateFormat}
     
        />
      </LocalizationProvider>
    );
  }
};

export default DatePickerDX;
