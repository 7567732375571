import { TextField, MenuItem } from "@mui/material";

import Skeleton from "react-loading-skeleton";

const SelectListDX = (props: any) => {

    const isError = props.error ? true : false;
    const helperText = props.helperText; // ?? props.label;
    const loading = props.loading ?? false;


    if (loading)
        return (
            <Skeleton
                containerClassName="skeleton-container"
                style={{ height: 56 }}
            />
        );

    else
        return (
            <TextField
                select
                fullWidth
                error={isError}
                helperText={props.error ?? helperText}
                onChange={props.handleChange}
                defaultValue={props.value}
                value={props.value}
                {...props}

            >

                {props.items && props.items.length != 0 ?
                    props.items.map((item: any, index: number) => (
                        <MenuItem
                            key={props.name + index}
                            value={item.value}
                            selected={props.value === item.value ? true : false}
                        >
                            {item.text}
                        </MenuItem>
                    )) :

                    <p style={{ padding: "0 8px", height: "20px", textAlign: "initial" }}>
                        No Item
                    </p>


                }
            </TextField>
        );
};

export default SelectListDX;
