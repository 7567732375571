import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Tooltip,
  ListItemIcon,
  Avatar,
} from "@mui/material";
import {
  AccountCircle,
  NotificationsNone as NotificationIcon,
  MoreVert as MoreIcon,
  Menu as MenuIcon,
  Logout,
} from "@mui/icons-material";



import MobileMenu from "./mobilemenu";
// import ProfileModal from "../../editmodals/profilemodal";
import { useNavigate } from "react-router-dom";
import BoxDX from "../CustomBox";

import { useAuthContext } from "../../context/authcontext";
import { AccountMenu } from './AccountMenu'
export default function MainAppBarDX() {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openProfile, setOpenProfile] = useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const onProfileClick = () => {
    setOpenProfile(!openProfile);
    handleMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* {openProfile && (
        <ProfileModal
          open={openProfile}
          onClose={() => setOpenProfile(false)}
        />
      )} */}

      <MenuItem>
        <IconButton size="large">
          <NotificationIcon />
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem>
        <IconButton size="large" onClick={onProfileClick}>
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    // <BoxDX sx={{ }}>
      <AppBar
        // position="fixed"
        sx={{
          boxShadow: 0,
          backgroundColor: "transparent",
          color: "black",
        }}
      >
        <Toolbar>
          <BoxDX>
            {/* <img src={"https://fakeimg.pl/150x50"} height={50} /> */}
          </BoxDX>
          <BoxDX sx={{ flexGrow: 1 }} />
          {/* <BoxDX
            sx={{ display: { xs: "flex", md: "none" }, alignItems: "center" }}
          >
            <IconButton size="large" onClick={toggleMobileMenu}>
              <MenuIcon />
            </IconButton>
          </BoxDX> */}
          <BoxDX
            sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
          >
            {/* <IconButton size="large">
              <NotificationIcon />
            </IconButton> */}
            <AccountMenu />
          </BoxDX>
          <BoxDX sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </BoxDX>
        </Toolbar>
      </AppBar>
  );
}


{/* {renderMobileMenu}

<MobileMenu open={mobileMenuOpen} closeMenu={toggleMobileMenu} />
</BoxDX> */}