import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import ListPageDX from "../components/Listpage";
import useSponsorshipService from "../shared/services/sponsorshipservice";
import SponsorshipModal from "../components/editmodals/sponsorshipmodal";

const Sponsorships = () => {
  const { getAllSponsorships, deleteSponsorship } = useSponsorshipService();
  const [sponsorshipId, setSponsorshipId] = useState<any>(null);
  const [actionType, setActionType] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [refetch, setRefetch] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Sponsor Name",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "sponsorName",
      headerName: "In Loving Memory of",
      flex: 1,
    },
    {
      field: "sponsorDate",
      headerName: "Date",
      flex: 1,
      valueFormatter(params) {
        return new Date(params.value).toLocaleDateString();
      },
    },
    {
      field: "paymentStatus",
      headerName: "Status",
      flex: 1,
      renderCell(params) {
        return (
          <span style={{ color: params.value === "UNPAID" ? "red" : "green" }}>
            {params.value}
          </span>
        );
      },
    },
  ];

  const onEdit = (sponsorship: any) => {
    setSponsorshipId(sponsorship._id);
    setOpen(!open);
    setActionType("edit");
  };

  const onCopy = (sponsorship: any) => {
    setSponsorshipId(sponsorship._id);
    setOpen(!open);
    setActionType("copy");
  };

  const toCreate = () => {
    setOpen(!open);
  };

  const onClose = (refreshPage: boolean = false) => {
    setSponsorshipId(null);
    setOpen(!open);
    setActionType(null);

    if (refreshPage) setRefetch(!refetch);
  };

  return (
    <>
      {open && (
        <SponsorshipModal
          open={open}
          handleClose={onClose}
          sponsorshipId={sponsorshipId}
          actionType={actionType}
        />
      )}
      <ListPageDX
        listTitle="Sponsorships"
        columns={columns}
        getRowId={(row: any) => row._id}
        // toCreate={toCreate}
        onEdit={onEdit}
        // onCopy={onCopy}
        searchAndFilter={true}
        refetch={refetch}
        onDelete={deleteSponsorship}
        deleteMessage="Sponsorship deleted successfully"
        fetchData={getAllSponsorships}
      />
    </>
  );
};

export default Sponsorships;
