import React, { useState, useEffect } from "react";
import { Container, CircularProgress } from "@mui/material";

const LoadingOverlay = (props: any) => {
  const { styles } = props
  return (
    <Container
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        backgroundColor: "black",
        opacity: 0.7,
        top: 0,
        zIndex: 200,
        width: "100%",
        maxWidth: "100%",
        ...styles
      }}
    >
      <CircularProgress />
    </Container>
  );
};

export default LoadingOverlay;
