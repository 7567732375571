import { Dialog, DialogTitle, DialogContent, Typography, DialogActions } from "@mui/material";
import GridDX from "../Layout/CustomGrid";
import ButtonDX from "../Button";


const ConfirmModal = (props: any) => {
  
  return (
    <Dialog
      open={props.open}
      onClose={props.onNoClick}
      fullWidth
      maxWidth={"xs"}
      disableEscapeKeyDown
    >
      <DialogTitle sx={{ textAlign: "center" }}>Are You Sure?</DialogTitle>
      <DialogContent>
        <GridDX justifyContent="center" direction="column">
          <Typography sx={{ textAlign: "center" }}  >
            unsaved data
          </Typography>
        </GridDX>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-around" }}>
        <ButtonDX
          variant="outlined"
          onClick={props.onNoClick}
        >
          No
        </ButtonDX>
        <ButtonDX
          onClick={() => {
            props.onYesClick();
          }}
        >
          Yes
        </ButtonDX>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmModal;