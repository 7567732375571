import { createTheme } from "@mui/material/styles";
import { primary,secondary } from "../../utils/colors";


const theme = createTheme({
    // Customize the theme by providing overrides
    palette: {
        primary: {
            main: primary.DEFAULT, // Change the primary color
        },
        secondary: {
            main: secondary.DEFAULT, // Change the secondary color
        },
    },
    button: {
        // Change the default text color
        color: "#fff",
    }
    
    // typography: {
    //     fontFamily: "Arial, sans-serif", // Change the default font
    // },
});

export default theme;
