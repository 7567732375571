import React from 'react';

const ButtonDX = (props: any) => {
  const { fullWidth, className, ...restProps } = props;

  // Determine the class for maxWidth based on the fullWidth prop
  const maxWidthClass = fullWidth ? 'max-w-full' : 'max-w-sm';

  return (
    <button
      className={` bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded  w-40 ${className}`}
      {...restProps}
    >
      {props.children}
    </button>
  );
};

export default ButtonDX;
