import React, { createContext, useContext, useEffect, useState } from "react";
import { ReactNode } from "react";

const AuthContext = createContext<any | null>(null);
const useAuthContext = () => useContext(AuthContext);

interface AuxProps {
  children: ReactNode;
}

const AuthProvider = ({ children }: AuxProps) => {
  const SESSION_NAME = "cityofknowledge_session";

  const [updatedData, setUpdatedData] = useState<any>(null);

  const [state, dispatch] = React.useReducer(
    (prevState: any, action: any) => {
      switch (action.type) {
        case "RESTORE_TOKEN":
          return {
            ...prevState,
            userToken: action.token,
            isLogedIn: true,
          };
        case "SIGN_IN": {
          return {
            ...prevState,
            isLoggedIn: true,
            userData: action.userData,
            userToken: action.token,
          };
        }
        case "SIGN_OUT":
          return {
            ...prevState,
            isLoggedIn: false,
            userData: null,
            userToken: null,
            discrepantData: null,
          };
      }
    },
    {
      isLoggedIn: false,
      userData: null,
      userToken: null,
    }
  );
  const [authInitialized, setAuthInitialized] = useState(false);

  useEffect(() => {
    const sessionInfo = localStorage.getItem(SESSION_NAME);


    if (sessionInfo && sessionInfo.length > 0) {
      try {
        const sessionInfoJSON = JSON.parse(sessionInfo);

        // decrypt the stored token to memory
        signIn(
          sessionInfoJSON,
          sessionInfoJSON.token ? window.atob(sessionInfoJSON.token) : ""
        );
      } catch (ex) {
        console.log("Error parsing session info:", ex);
      }
    }
    setAuthInitialized(true);
  }, []);

  const signIn = (data: any, token: string) => {
    const encodedToken = window.btoa(token);
    // store the token as encrypted
    localStorage.setItem(
      SESSION_NAME,
      JSON.stringify({ ...data, token: encodedToken })
    );
    dispatch({
      type: "SIGN_IN",
      userData: data,
      token: token,
    });
  };

  const signOut = () => {
    localStorage.removeItem(SESSION_NAME);
    dispatch({ type: "SIGN_OUT" });
  };

  const getUserDetails = async () => {
    const sessionInfo = localStorage.getItem(SESSION_NAME);

    if (sessionInfo && sessionInfo.length > 0) {
      try {
        const sessionInfoJSON = JSON.parse(sessionInfo);
        return sessionInfoJSON;
      } catch (ex) {
        console.log("Error parsing session info:", ex);
        return {};
      }
    }
  };

  // FIX: requires fixing update state and then update storage
  const updateUserDetails = async (data: any) => {
    const sessionInfo = localStorage.getItem(SESSION_NAME);

    if (sessionInfo && sessionInfo.length > 0) {
      try {
        let sessionInfoJSON = JSON.parse(sessionInfo);
        sessionInfoJSON = {
          ...sessionInfoJSON,
          ...data,
        };
        setUpdatedData(sessionInfoJSON);

        localStorage.setItem(SESSION_NAME, JSON.stringify(sessionInfoJSON));
      } catch (ex) {
        console.log("Error parsing session info:", ex);
        return {};
      }
    }
  };

  const removeUserData = async () => {
    localStorage.removeItem(SESSION_NAME);
  };

  return (
    <AuthContext.Provider
      value={{
        authInitialized,
        isLoggedIn: state.isLoggedIn,
        token: state.userToken,
        userData: state.userData,
        signIn,
        signOut,
        getUserDetails,
        updateUserDetails,
        removeUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuthContext };
